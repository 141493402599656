import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'ai-driven-digital',
	templateUrl: './ai-driven-digital.component.html',
	styleUrls: ['./ai-driven-digital.component.css'],
})
export class AiDrivenDigitalComponent implements OnInit {
	public consultMeForm: any;
	public submitted: boolean = false;
	public showSignupbtn: boolean;
	public showSpinner: boolean = false;
	public ErrorMessage: any;

	constructor(
		private formBuilder: FormBuilder,
		private service: UserService,
		private Requestmeta: Meta,
		private RequestpageTitle: Title
	) {}

	ngOnInit() {
		if (localStorage.getItem('userData')) {
			this.showSignupbtn = false;
		} else {
			this.showSignupbtn = true;
		}
		//initialize the form validations for contactUsForm...
		this.consultMeForm = this.formBuilder.group({
			full_name: ['', [Validators.required]],
			email: [
				'',
				[
					Validators.required,
					Validators.email,
					Validators.pattern(
						'^[a-zA-Z0-9_.+-]+@(?!gmail)(?!yahoo)(?!hotmail)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
					),
				],
			],
			mobile: ['', [Validators.required]],
			company_name: ['', [Validators.required]],
		});
		this.RequestpageTitle.setTitle(
			'Ai Driven Digital Development - Intellisqr'
		);
		this.Requestmeta.addTags([
			{
				name: 'description',
				content:
					'Intellisqr provide best Customer Data Platform gives you persistent, unified, always up-to-date intelligence on your accounts, customers, relevant contacts, and prospects that is accessible across all your systems and platforms.',
			},
			{ name: 'keywords', content: 'Best Customer Data platform' },
		]);
	}

	get g() {
		return this.consultMeForm.controls;
	}

	//onSubmit for submit details....
	onSubmit() {
		this.submitted = true;

		if (this.consultMeForm.invalid) {
			return;
		} else if (
			!this.consultMeForm.value.full_name.replace(/^\s+/g, '').length
		) {
			this.consultMeForm.value.full_name = ' ';
			return;
			// }
			// else if(!this.consultMeForm.value.message.replace(/^\s+/g, '').length){
			//   this.consultMeForm.value.message = ' ';
			//   return;
		} else {
			var formData = new FormData();
			formData.append('contact_type', '1');
			formData.append('user_name', this.consultMeForm.value.full_name);
			formData.append('email', this.consultMeForm.value.email);
			formData.append('mobile', this.consultMeForm.value.mobile);
			formData.append('company_name', this.consultMeForm.value.company_name);
			formData.append('description', 'For request meeting');
			this.showSpinner = true;
			this.service.postData(formData).subscribe((response: any) => {
				if (response.status == 1) {
					this.consultMeForm.reset();
					let element1: HTMLElement = document.getElementById(
						'showThankscontactPopup'
					) as HTMLElement;
					element1.click();
					// this.service.successAlert(response.message);
					this.showSpinner = false;

					this.submitted = false;
				} else {
					this.submitted = false;
					this.showSpinner = false;
					this.ErrorMessage = response.message;
					let ErrorMEssageModal: HTMLElement = document.getElementById(
						'errorMessage_contact'
					) as HTMLElement;
					ErrorMEssageModal.click();
					// this.service.dangerAlert(response.message);
				}
			});
		}
	}
}
